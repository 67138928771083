import 'custom-event-polyfill';

import navigation from './navigation';
import skipLinkFocusFix from './skip-link-focus-fix';

import '../scss/manifest.scss';

navigation.init();
skipLinkFocusFix.init();

navigation.init();
skipLinkFocusFix.init();


if (document.getElementsByClassName('swiper-container').length > 0) {
  import(/* webpackChunkName: "Swiper" */ './swiper').then(({ default: _ }) => {
    _.init();
  });
}

if (document.getElementsByClassName('js-masonry-with-columns').length > 0) {
  import(/* webpackChunkName: "Gallery" */ './gallery').then(({ default: _ }) => {
    _.init();
  });
}
